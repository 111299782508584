import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import {
  ROUTE_CREATE,
  ROUTE_FORM_GENERATOR,
  ROUTE_FORM_GENERATOR_FORM,
} from '../../routes/routes-constants'
import { getFormContainer } from '../../container/form-module'
import { FORM_SERVICE_KEY, USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { FormService } from '../../modules/forms/services/FormService'
import { Form } from '../../modules/forms/models/Form'
import { Query, QueryParam } from '../../common/api/Query'
import { LoadingSpinner } from 'components/loading-spinner/LoadingSpinner'
import styles from './Table.module.css'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { AppTable, Field } from '../../components/table'
import { Actions, Pager } from '../../components/table/types'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { UserForm } from '../../modules/forms/models/UserForm'
import genericStyle from '../../common/utils/generic.module.css'
import createNewForm from '../../assets/resource_icons/ico-form-crear.svg'
import assignIcon from '../../assets/table_icons/ico-enviar.svg'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'
import { forkJoin, Observable } from 'rxjs'
import { reduceString } from '../../common/utils/strings'
import { IStatusService } from '../../common/status/StatusService'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { finalize } from 'rxjs/operators'

const formContainer = getFormContainer()
const formService = formContainer.get<FormService>(FORM_SERVICE_KEY)
const userFormService = formContainer.get<UserFormService>(USER_FORM_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)

export function Table() {
  const { t } = useTranslation()
  const [forms, setForms] = useState<ItemList<Form>>(emptyList<Form>())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [formsPerPage, setFormsPerPage] = useState<number>(10)
  const [userForms, setUserForms] = useState<string[]>([])

  const getUserForms = (ids: string[]): Observable<UserForm[]> =>
    forkJoin(
      ids.map((id) =>
        userFormService.getFilteredList(
          new Query({
            query: [new QueryParam<UserForm>('formID', id)],
          })
        )
      )
    ) as unknown as Observable<UserForm[]>

  useEffect(() => {
    getData()
    if (count > 0) {
      setPager({
        page,
        count,
        handleChangePage: handlePaginationChange,
        rowsPerPage: formsPerPage,
        handleChangeRowsPerPage,
      })
    }
  }, [page, count, formsPerPage])

  const getData = () => {
    setIsLoading(true)
    formService
      .getFilteredList(
        new Query({
          pager: { offset: (page - 1) * formsPerPage, limit: formsPerPage },
          sort: [{ field: 'title' }],
        })
      )
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe((res) => {
        setForms(res)
        setCount(res.count)

        // TODO Make edit and remove buttons hidden or blocked if form has been filled
        getUserForms(res.items.map((f) => f.id || '')).subscribe((res) =>
          setUserForms(res.map((uf) => uf.id || ''))
        )
      })
  }
  const createForm = () => navigate(`${ROUTE_FORM_GENERATOR_FORM}/${ROUTE_CREATE}`)

  const editForm = (form: Form) => navigate(`${ROUTE_FORM_GENERATOR_FORM}/${form.id}`)

  const removeForm = (form: Form) => {
    if (!form?.id) {
      return
    }
    formService
      .delete(form?.id)
      .pipe(finalize(() => getData()))
      .subscribe(() => {
        statusService.sendStatus({ variant: 'success' })
      })
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const resultForm = (form: Form) => navigate(`${ROUTE_FORM_GENERATOR_FORM}/${form.id}/results`)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setFormsPerPage(10)
      return
    }
    setFormsPerPage(Number.parseInt(event.target.value))
  }

  const associateForm = (form: Form) => navigate(`${ROUTE_FORM_GENERATOR}/${form.id}/assignTo`)

  const fields: Field<Form>[] = [
    {
      name: 'title',
      label: t('title'),
    },
    {
      name: 'description',
      label: t('description'),
      renderFunc: (f, i) => reduceString(i.description, 60),
    },
  ]

  const actions: Actions<Form> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: resultForm,
        icon: seeIcon,
        label: 'see',
      },
      {
        handler: editForm,
        icon: editIcon,
        label: 'edit',
        //hidden: hideForm,
      },
      {
        handler: associateForm,
        icon: assignIcon,
        label: 'add',
      },
      {
        handler: removeForm,
        icon: deleteIcon,
        label: 'delete',
        //hidden: hideForm,
      },
    ],
  }

  return (
    <Box className={genericStyle.pageContainer}>
      {!isLoading ? (
        <Box className={styles.container}>
          <Box className={styles.buttonContainer}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('createTemplateForm')}
              startIcon={createNewForm}
              handler={createForm}
            />
          </Box>
          {count > 0 ? (
            <AppTable
              items={forms.items}
              rowKeyField="id"
              fields={fields}
              actions={actions}
              pager={pager}
            />
          ) : (
            <h1>{t('noForms')}</h1>
          )}
        </Box>
      ) : (
        <LoadingSpinner className={styles.loadingSpinner} />
      )}
    </Box>
  )
}
