import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from 'modules/users'
import { emptyUserDTO, UserDTO } from 'modules/users/models/User'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import styles from './Editor.module.css'
import genericStyle from 'common/utils/generic.module.css'
import { ROUTE_NOTIFICATIONS } from 'routes/routes-constants'
import { navigate } from '@reach/router'
import { Alert, Skeleton } from '@material-ui/lab'
import { Header } from 'components/header/Header'
import { Button, Typography, Box } from '@mui/material'
import { UpdatePasswordDTO } from 'modules/users/models/UpdatePasswordDTO'
import { IStatusService } from 'common/status/StatusService'
import { getAppContainer, STATUS_SERVICE_KEY } from 'container/app'
import AvatarProfile from 'components/avatar-profile/AvatarProfile'
import { Input } from 'pages/user-profile/userProfileStylesMui'
import { useIsOpenMenuLeftContext } from 'common/utils/isOpenMenuLeftContext'
import style from './EditorNew.module.css'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)

enum Messages {
  SUCCESS = 'changesSavedSuccessfully',
}

export function Editor() {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [userToEdit, setUserToEdit] = useState<UserDTO>(emptyUserDTO())
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [message, setMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { openMenuLeft } = useIsOpenMenuLeftContext()

  useEffect(() => {
    userService.getByID(loggedUser?.id || '').subscribe((res) => {
      res && setUserToEdit(res.toDTO())
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    setMessage('')
  }, [userToEdit])

  /*
  useEffect(() => {
    updatePassword &&
    userService.changePassword(updatePassword).subscribe((res:any) => {
      if (res === "incorrectPassword") {
        setErrorMessage('La contraseña actual es incorrecta')
        setStopUpdate(true)
      }
    })
  }, [updatePassword])*/

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setUserToEdit(Object.assign({ ...userToEdit }, { [e.target.name]: e.target.value }))

  const goHome = () => navigate(ROUTE_NOTIFICATIONS)

  const validatePassword = () => {
    if (!newPassword && !repeatPassword) {
      setErrorMessage(t('voidFields'))
      return false
    }

    if (newPassword !== repeatPassword) {
      setErrorMessage('passNotEquals')
      return false
    }

    return true
  }

  const saveData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (oldPassword) {
      if (validatePassword() && loggedUser) {
        setErrorMessage('')
        const newPasswordSend: UpdatePasswordDTO = {
          id: loggedUser.id,
          password: oldPassword,
          newPassword: newPassword,
        }

        userService.changePassword(newPasswordSend).subscribe((res: any) => {
          if (res === 'incorrectPassword') {
            setErrorMessage('Contraseña actual incorrecta')
          } else {
            userService
              .update(userToEdit)
              .subscribe(() => statusService.sendStatus({ variant: 'success' }))
          }
        })
      }
    } else if (!oldPassword) {
      setErrorMessage('incorrectOldPass')
      return false
    } else {
      userService
        .update(userToEdit)
        .subscribe(() => statusService.sendStatus({ variant: 'success' }))
    }
  }

  // const themeStyles = makeStyles(() => ({
  //   input: {
  //     borderRadius: 32,
  //     borderColor: '#E8E7EC',
  //     fontFamily: 'Montserrat',
  //     fontWeight: 500,
  //     fontSize: '20px',
  //     color: '#474747',
  //     height: 35,
  //     '&.Mui-focused': {
  //       '& fieldset.MuiOutlinedInput-notchedOutline': {
  //         borderColor: '#a9ba37 !important',
  //       },
  //     },
  //   },
  //   inputUnderline: {
  //     '&:after': {
  //       borderColor: '#a9ba37 !important',
  //     },
  //   },
  //   selectInput: {
  //     '& ': {
  //       width: '29rem !important',
  //     },

  //     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
  //       borderColor: '#a9ba37 !important',
  //     },
  //   },

  //   label: {
  //     [`&.${inputLabelClasses.shrink}`]: {
  //       color: '#a9ba37',
  //       marginTop: '-1%',
  //       backgroundColor: 'white',
  //       borderColor: '#a9ba37',
  //     },
  //   },
  // }))
  // const classes = themeStyles()

  return (
    <Box
      className={style.pageContainer}
      sx={{
        margin: !openMenuLeft ? '0 150px' : '0 50px',
      }}
    >
      <Box className={genericStyle.pageContainer}>
        <Box className={genericStyle.container}>
          {!isLoading ? (
            <form className={styles.form} onSubmit={saveData}>
              <Box display={'flex'} bgcolor="transparent" m="1rem" width={'100%'}>
                <Box className={styles.avatarContainer}>
                  <AvatarProfile />
                  <Box>
                    <Typography
                      className={styles.userName}
                    >{`${userToEdit.firstName} ${userToEdit.lastName}`}</Typography>
                    <Typography className={styles.roleText}>
                      {userToEdit.isPatient ? `${t('patient')}` : `${t('doctor')}`}
                    </Typography>
                  </Box>
                </Box>
                <Box width={'100%'}>
                  <Box className={styles.headerContainer}>
                    <Typography className={styles.headerTitle}>{t('userData')}</Typography>
                  </Box>
                  <Box mb={3} className={styles.textFieldBox}>
                    <Box>
                      <Typography className={styles.inputTitle}>{t('name')}</Typography>
                      <Input
                        id={'firstName'}
                        label=""
                        key={'firstName'}
                        name="firstName"
                        className={styles.textField}
                        value={userToEdit.firstName}
                        onChange={handleInput}
                        type="text"
                        required={true}
                        variant={'outlined'}
                        size={'small'}
                        // InputLabelProps={{ className: classes.label }}
                        // InputProps={{ className: classes.input }}
                        disabled={userToEdit.isPatient}
                      />
                    </Box>
                    <Box>
                      <Typography className={styles.inputTitle}>{t('lastName')}</Typography>
                      <Input
                        id={'lastName'}
                        label={''}
                        key={'lastName'}
                        name="lastName"
                        className={styles.textField}
                        value={userToEdit.lastName}
                        onChange={handleInput}
                        type="text"
                        required={true}
                        variant={'outlined'}
                        size={'small'}
                        // InputLabelProps={{ className: classes.label }}
                        // InputProps={{ className: classes.input }}
                        disabled={userToEdit.isPatient}
                      />
                    </Box>
                  </Box>
                  <Box mb={3} className={styles.textFieldBox}>
                    <Box className={style.textFieldCol}>
                      <Typography className={styles.inputTitle}>{t('email')}</Typography>
                      <Input
                        id={'email'}
                        label={''}
                        key={'email'}
                        name="email"
                        className={styles.textField}
                        value={userToEdit.email}
                        type="email"
                        onChange={handleInput}
                        required={true}
                        variant={'outlined'}
                        size={'small'}
                        // InputLabelProps={{ className: classes.label }}
                        // InputProps={{ className: classes.input }}
                        disabled={true}
                      />
                    </Box>
                    <Box className={style.textFieldCol}>
                      <Typography className={styles.inputTitle}>{t('center')}</Typography>
                      <Input
                        id={'center'}
                        label={''}
                        key={'center'}
                        name="center"
                        className={styles.textField}
                        value={'Centro demo'}
                        type="text"
                        onChange={handleInput}
                        required={true}
                        variant={'outlined'}
                        size={'small'}
                        // InputLabelProps={{ className: classes.label }}
                        // InputProps={{ className: classes.input }}
                        disabled={true}
                      />
                    </Box>
                  </Box>
                  <Box mb={3} className={styles.textFieldBox}>
                    <Box className={style.textFieldCol}>
                      <Typography className={styles.inputTitle}>{t('role')}</Typography>
                      <Input
                        id={'role'}
                        label={''}
                        key={'role'}
                        name="role"
                        className={styles.textField}
                        value={userToEdit.isPatient ? `${t('patient')}` : `${t('doctor')}`}
                        type="text"
                        onChange={handleInput}
                        required={true}
                        variant={'outlined'}
                        size={'small'}
                        // InputLabelProps={{ className: classes.label }}
                        // InputProps={{ className: classes.input }}
                        disabled={true}
                      />
                    </Box>
                  </Box>
                  <Box mb={3} className={styles.headerContainer}>
                    <Typography className={styles.headerTitle} sx={{ marginTop: 4 }}>
                      {t('changePass')}
                    </Typography>
                  </Box>
                  <Box mb={3} className={styles.textFieldBox}>
                    <Box className={style.textFieldCol}>
                      <Typography className={styles.inputTitle}>{t('currentPassword')}</Typography>
                      <Input
                        id={'currentPass'}
                        label={''}
                        key={'currentPass'}
                        name="currentPass"
                        className={styles.textFieldPass}
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        variant={'outlined'}
                        size={'small'}
                      />
                    </Box>
                  </Box>
                  <Box className={style.lastRow}>
                    <Box mb={3} className={styles.textFieldBox}>
                      <Box className={style.textFieldCol}>
                        <Typography className={styles.inputTitle}>{t('newPass')}</Typography>
                        <Input
                          id={'newPass'}
                          label={''}
                          key={'newPass'}
                          name="newPass"
                          className={styles.textFieldPass}
                          type="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          variant={'outlined'}
                          size={'small'}
                        />
                      </Box>
                    </Box>
                    <Box mb={3} className={styles.textFieldBox}>
                      <Box className={style.textFieldCol}>
                        <Typography className={styles.inputTitle}>{t('repeatNewPass')}</Typography>
                        <Input
                          id={'repeatNewPass'}
                          label={''}
                          key={'repeatNewPass'}
                          name="repeatNewPass"
                          value={repeatPassword}
                          className={styles.textFieldPass}
                          type="password"
                          onChange={(e) => setRepeatPassword(e.target.value)}
                          variant={'outlined'}
                          size={'small'}
                        />
                      </Box>
                    </Box>
                    <Box className={style.actionBtns}>
                      <Button className={styles.buttonSubmit} type={'submit'} onClick={() => {}}>
                        {t('save')}
                      </Button>
                      <Button
                        className={styles.buttonCancel}
                        type={'button'}
                        onClick={() => goHome()}
                      >
                        {t('cancel')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {message && (
                <Box mb={3}>
                  <Alert severity="success" key="message" id="message">
                    {t(message)}
                  </Alert>
                </Box>
              )}
              {errorMessage && (
                <Box mb={3}>
                  <Alert severity="error" key="errorMessage" id="errorMessage">
                    {t(errorMessage)}
                  </Alert>
                </Box>
              )}
            </form>
          ) : (
            <form onSubmit={saveData}>
              <Box bgcolor="white" m="1rem">
                <Box className={styles.headerContainer}>
                  <Header label={t('userData')} />
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box style={{ borderBottom: '3px solid  #a9ba37' }}>
                  <p className={styles.label}>{t('changePass')}</p>
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                </Box>
              </Box>
              <Box className={styles.buttonContainer} display="flex">
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('cancel')}
                  handler={() => goHome()}
                />
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('save')}
                  handler={() => {}}
                />
              </Box>
            </form>
          )}
        </Box>
      </Box>
    </Box>
  )
}
