import { FormFieldType } from '../../modules/forms/enums/FormFieldType'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { FormControlLabel } from '@mui/material'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { FORM_SERVICE_KEY, USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { FormService } from '../../modules/forms/services/FormService'
import { getFormContainer } from '../../container/form-module'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { FormCard } from '../../components/form-card/FormCard'
import genericStyle from '../../common/utils/generic.module.css'
import {
  emptyUserFormDTO,
  fromModel as userFormModel,
  UserFormDTO,
  UserFormValueDTO,
} from '../../modules/forms/models/UserFormDTO'
import { FormFieldDTO, fromModelFormField } from '../../modules/forms/models/FormDTO'
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers'
import CustomToolbar from '../calendar/CustomToolbar'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_PATIENT_FORMS } from '../../routes/routes-constants'
import { navigate } from '@reach/router'
import { FileItem } from '../../components/form-card/FileItem'
import { File as F } from '../../modules/files/models/File'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { Alert } from '@material-ui/lab'
import styles from './View.module.css'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/users/container'
import { ReadingStatus } from '../../modules/forms/enums/ReadingStatus'
import { CustomModal } from '../../components/modal/CustomModal'
import style from './View.module.css'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'

type PatientFormViewProps = {
  id: string
  edit: boolean
}

const rows = 10
const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const formContainer = getFormContainer()
const userFormService = formContainer.get<UserFormService>(USER_FORM_SERVICE_KEY)
const formService = formContainer.get<FormService>(FORM_SERVICE_KEY)
const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

export function View(props: PatientFormViewProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActive()

  const [userForm, setUserForm] = useState<UserFormDTO>(
    emptyUserFormDTO(loggedUser?.id || '', circle?.id || '')
  )
  const [formFields, setFormFields] = useState<FormFieldDTO[]>([])
  const [saveAndSend, setSaveAndSend] = useState<boolean>(false)
  const [files, setFiles] = useState<Map<string, F[]>>(new Map())
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [formTitle, setFormTitle] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalSend, setOpenModalSend] = useState<boolean>(false)
  const [formFieldNoAnswer, setFormFielNoAnswer] = useState<string>('')
  const [date, setDate] = useState<Date>(new Date())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [permanent, setPermanent] = useState<number>(0)

  useEffect(() => {
    if (props.id) {
      userFormService.getByID(props.id).subscribe((uf) => {
        if (uf) {
          setUserForm(userFormModel(uf))
          if (uf.formID) {
            formService.getByID(uf.formID).subscribe((f) => {
              if (f) {
                setFormFields(f.formFields.map((ff) => fromModelFormField(ff, f.id)))
                setFormTitle(f.title)
                setIsLoading(false)
              }
            })
          }
        }
      })
    }
    const interval = setInterval(() => {}, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  /*  useEffect(() => {
    const fileFields = formFields.filter((ff) => ff.type === FormFieldType.File)
    fileFields.forEach((ff) => {
      const ufv = userForm.userFormValues.find((ufv) => ufv.formFieldID === ff.id)
      if (ufv && ufv.values && ufv.values.length > 0) {
        const newMap = new Map()
        fileService.getByID(ufv.values[0]).subscribe((file) => {
          if (file) {
            newMap.set(ff.id, [file])
            setFiles(new Map(newMap))
          }
        })
      }
    })
  }, [formFields])*/

  const handleChange = (formFieldID: string, values: string[]) => {
    if (userForm) {
      const index = userForm.userFormValues.findIndex((ufv) => ufv.formFieldID === formFieldID)

      const ufv = userForm.userFormValues
      ufv[index] = {
        userFormID: userForm.id || '',
        formFieldID,
        values,
        date: date,
        done: true,
      }

      setUserForm(Object.assign({ ...userForm }, { userFormValues: ufv }))
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // console.log(e.target.name, e.target.value)
    handleChange(e.target.name, [e.target.value])
  }

  const handleDate = (e: MaterialUiPickersDate, formFieldID: string) => {
    if (e) {
      handleChange(formFieldID, [e.toDate().toDateString()])
    }
  }

  const handleTime = (e: MaterialUiPickersDate, formFieldID: string) => {
    if (e) {
      handleChange(formFieldID, [e.toDate().toISOString()])
    }
  }

  const handleSelect = (e: SelectChangeEvent<string[]>, formFieldID: string, multiple: boolean) => {
    handleChange(
      formFieldID,
      multiple ? (e.target.value as string[]).filter((o) => o !== '') : [e.target.value as string]
    )
  }

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.name, [e.target.checked ? 'true' : 'false'])
  }

  const handleRadioButton = (formFieldID: string, option: string) => {
    handleChange(formFieldID, [option])
  }

  const handleFiles = (formFieldID: string, value: F[]) => {
    const newMap = new Map(files)
    newMap.set(formFieldID, value)
    setFiles(newMap)
    handleChange(
      formFieldID,
      value.map((f) => f.id || '')
    )
  }

  const generateTextField = (
    formField: FormFieldDTO,
    values: string[] | undefined,
    multiline: boolean
  ) => {
    return (
      <Box mb={3}>
        <p>
          {formField.title} {formField.required && <>*</>}
        </p>
        <TextFieldItem
          key={formField.id}
          field={formField.id}
          type={formField.type === FormFieldType.Number ? 'number' : 'text'}
          label={''}
          rows={multiline ? rows : undefined}
          value={values?.join(' ') ?? ''}
          handleChange={handleInput}
          required={formField?.required}
          disabled={!props.edit}
        />
      </Box>
    )
  }

  const generateNoResponseField = (formField: FormFieldDTO, values: string[] | undefined) => {
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
      </Box>
    )
  }

  const generateDateField = (formField: FormFieldDTO, values: string[] | undefined) => {
    let date = new Date()
    if (values && values.length > 0 && values[0] !== '') {
      date = new Date(values[0])
    }
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
        <KeyboardDatePicker
          key={formField.id}
          id={formField.id}
          name={formField.id}
          autoOk={true}
          variant="inline"
          format="DD/MM/YYYY"
          margin="normal"
          label={''}
          value={date}
          invalidDateMessage={t('invalidDateMessage')}
          onChange={(e) => handleDate(e, formField.id)}
          ToolbarComponent={CustomToolbar}
          required={formField.required}
          disabled={!props.edit}
        />
      </Box>
    )
  }

  const generateTimeField = (formField: FormFieldDTO, values: string[] | undefined) => {
    let date = new Date()
    if (values && values.length > 0 && values[0] !== '') {
      date = new Date(values[0])
    }
    return (
      <Box>
        <p>{formField.title}</p>
        <KeyboardTimePicker
          key={formField.id}
          id={formField.id}
          name={formField.id}
          variant="inline"
          autoOk
          format="HH:mm"
          margin="normal"
          label={''}
          value={date}
          invalidDateMessage={t('invalidHourMessage')}
          onChange={(e) => handleTime(e, formField.id)}
          required={formField.required}
          disabled={!props.edit}
        />
      </Box>
    )
  }

  const generateDateTimeField = (formField: FormFieldDTO, values: string[] | undefined) => {
    let date = new Date()
    if (values && values.length > 0 && values[0] !== '') {
      date = new Date(values[0])
    }
    return (
      <Box>
        <p>{formField.title}</p>
        <KeyboardDateTimePicker
          key={formField.id}
          id={formField.id}
          name={formField.id}
          variant="inline"
          autoOk
          format="DD/MM/YYYY HH:mm"
          margin="normal"
          label={''}
          value={date}
          invalidDateMessage={t('invalidHourMessage')}
          onChange={(e) => handleTime(e, formField.id)}
          required={formField.required}
          disabled={!props.edit}
        />
      </Box>
    )
  }

  const generateSelectField = (
    formField: FormFieldDTO,
    values: string[] | undefined,
    multiple: boolean
  ) => {
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
        <Select
          key={formField.id}
          id={formField.id}
          variant={'outlined'}
          value={values}
          onChange={(e) => handleSelect(e, formField.id, multiple)}
          className={styles.select}
          multiple={multiple}
          disabled={!props.edit}
          required={formField.required}
        >
          {formField.optionValues.map((k) => (
            <MenuItem key={k} value={k}>
              {k}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }

  const generateCheckboxField = (formField: FormFieldDTO, values: string[] | undefined) => {
    return (
      <Box mb={3}>
        <p className={styles.checkbox}>{formField.title}</p>
        <Checkbox
          name={formField.id}
          checked={values && values.length > 0 ? values[0] === 'true' : false}
          onChange={handleCheckbox}
          disabled={!props.edit}
        />
      </Box>
    )
  }

  const generateRadioButtonField = (formField: FormFieldDTO, values: string[] | undefined) => {
    const radioButtons = Array.isArray(formField.optionValues) ? formField.optionValues : []
    return (
      radioButtons.length > 0 && (
        <Box mb={3}>
          <FormControl component="fieldset">
            <p>{formField.title}</p>
            <RadioGroup name={formField.title}>
              {radioButtons.map((o, i) => (
                <FormControlLabel
                  key={`${o}-${i}`}
                  value={o}
                  control={
                    <Radio
                      required={formField.required}
                      checked={(Array.isArray(values) ? values[0] : values) === o}
                    />
                  }
                  label={<span style={{ fontFamily: 'Poppins' }}>{o}</span>}
                  onChange={() => handleRadioButton(formField.id, o)}
                  disabled={!props.edit}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      )
    )
  }

  const generateFileField = (formField: FormFieldDTO) => {
    const fl = files.get(formField.id)
    return (
      <Box mb={3} key={formField.id + 'box'}>
        <p>{formField.title}</p>
        <FileItem
          key={formField.id}
          field={formField.id}
          filesID={[]}
          handleFiles={(_, f) => handleFiles(formField.id, f)}
          userID={loggedUser?.id || ''}
          cleanFiles={false}
          uploadRemoveFileDirectly={true}
          disabled={!props.edit || (fl ? fl.length > 0 : false)}
          defaultFile={fl || []}
        />
      </Box>
    )
  }

  const generateField = (formField: FormFieldDTO, userFormValue: UserFormValueDTO) => {
    switch (formField.type) {
      case FormFieldType.Text:
      case FormFieldType.TextArea:
      case FormFieldType.Number:
        const multiline = formField.type === FormFieldType.TextArea
        return generateTextField(formField, userFormValue.values, multiline)

      case FormFieldType.Date:
        return generateDateField(formField, userFormValue.values)

      case FormFieldType.Select:
      case FormFieldType.MultiSelect:
        const multiple = formField.type === FormFieldType.MultiSelect
        return generateSelectField(formField, userFormValue.values, multiple)

      case FormFieldType.CheckBox:
        return generateCheckboxField(formField, userFormValue.values)

      case FormFieldType.RadioButton:
        return generateRadioButtonField(formField, userFormValue.values)

      /*    case FormFieldType.File:
         return generateFileField(formField)*/

      case FormFieldType.Time:
        return generateTimeField(formField, userFormValue.values)

      case FormFieldType.DateTime:
        return generateDateTimeField(formField, userFormValue.values)
    }
  }

  const validateUserForm = (uf: UserFormDTO): boolean => {
    const fieldsRequired = formFields.filter((ff) => ff.required)
    const fieldsToValidate = fieldsRequired.filter((ff) =>
      uf.userFormValues.find(
        (ufv) =>
          ufv.formFieldID === ff.id &&
          ufv.values &&
          ((ufv.values.length > 0 && ufv.values[0] === '') || ufv.values.length === 0)
      )
    )

    if (fieldsToValidate.length > 0) {
      setErrorMessage(`${t('requiredFields')}: ${fieldsToValidate.map((f) => f.title).join(', ')}`)
      return false
    }

    setErrorMessage('')
    return true
  }

  const goBack = () => navigate(ROUTE_PATIENT_FORMS)

  const saveOrSendForm = () => {
    const values = userForm.userFormValues.map((ufv) => {
      const ff = formFields.find((ff) => ff.id === ufv.formFieldID)
      if (ff) {
        if (ufv.values && ufv.values.length > 0 && ufv.values[0] === '') {
          const newUserFormValue = Object.assign({ ...ufv }, { ...ufv }, { done: true })
          switch (ff.type) {
            case FormFieldType.Date:
              newUserFormValue.values = [new Date().toDateString()]
              break
            case FormFieldType.CheckBox:
              newUserFormValue.values = ['false']
              break
          }
          return newUserFormValue
        }
      }
      return ufv
    })

    const newUserForm = Object.assign(
      { ...userForm },
      {
        userFormValues: values,
        readingStatus: saveAndSend ? ReadingStatus.Completed : ReadingStatus.Pending,
      }
    )

    if (!validateUserForm(newUserForm)) {
      return
    }

    userFormService.update(newUserForm).subscribe()

    const userFormTemp: UserFormDTO = {
      id: userForm?.id || '',
      userID: userForm?.userID || '',
      circleID: userForm?.circleID || '',
      formID: userForm?.formID || '',
      userFormValues: [],
      readingStatus: userForm.readingStatus,
    }

    if (permanent === 1) {
      newUserForm.userFormValues.map((v: UserFormValueDTO) => {
        userFormTemp.userFormValues.push(v)
      })
    } else {
      newUserForm.userFormValues.map((v: UserFormValueDTO) => {
        userFormService.updateValue(v).subscribe()
      })
    }

    goBack().then()
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleCloseModalSend = () => {
    setOpenModalSend(false)
  }

  const handleSaveModal = () => {
    setOpenModal(false)
    saveOrSendForm()
  }

  const handleSaveModalSend = () => {
    setOpenModalSend(false)
    saveOrSendForm()
  }

  const handleSave = (val: boolean = false) => {
    setSaveAndSend(val)
    if (validateUserForm(userForm)) {
      setOpenModal(true)
    }
  }

  return (
    <Box className={style.container}>
      {isLoading && <LoadingSpinner className={loaderStyles.loadingSpinner} />}
      {!isLoading && (
        <Card>
          <CardHeader>{formTitle}</CardHeader>
          <CardContent>
            <FormCard>
              <form onSubmit={() => handleSave()}>
                {formFields
                  .sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0))
                  .map((ff, i) => {
                    const ufv = userForm.userFormValues.find((ufv) => ufv.formFieldID === ff.id)
                    return ufv && generateField(ff, ufv)
                    // return (
                    //   <p>
                    //     {ff.title} : {ufv?.values}
                    //   </p>
                    // )
                  })}
                {formFieldNoAnswer !== '' && (
                  <p style={{ marginBottom: '2%' }}>{formFieldNoAnswer}</p>
                )}
                {errorMessage && (
                  <Box mb={3}>
                    <Alert severity="warning" key="errorMessage" id="errorMessage">
                      {errorMessage}
                    </Alert>
                  </Box>
                )}
                <Box display="flex" justifyContent="space-between">
                  <AppButton
                    theme={ButtonTheme.NewSecondary}
                    type={'button'}
                    label={props.edit ? t('cancel') : t('back')}
                    handler={goBack}
                  />
                  {props.edit && !permanent && (
                    <AppButton
                      theme={ButtonTheme.NewPrimary}
                      label={t('save')}
                      type={'button'}
                      handler={(e) => {
                        e.preventDefault()
                        handleSave()
                      }}
                    />
                  )}
                  {props.edit && (
                    <AppButton
                      theme={ButtonTheme.NewPrimary}
                      label={t('send')}
                      type={'submit'}
                      handler={(e) => {
                        e.preventDefault()
                        handleSave(true)
                      }}
                    />
                  )}
                </Box>
              </form>
            </FormCard>
          </CardContent>
        </Card>
      )}

      <Modal open={openModal} className={style.eventModal} onClose={handleCloseModal}>
        <CustomModal
          handleClose={handleCloseModal}
          handleSave={handleSaveModal}
          title={t('saveForm')}
          warningText={t('saveFormText')}
        />
      </Modal>
      <Modal open={openModalSend} className={style.eventModal} onClose={handleCloseModalSend}>
        <CustomModal
          handleClose={handleCloseModalSend}
          handleSave={handleSaveModalSend}
          title="Enviar"
          warningText={t('sendCustomForm')}
        />
      </Modal>
    </Box>
  )
}
